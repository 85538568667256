<template>
  <div class="sampleDataRootWrap">
    <div class="sampleDataHeader">
      <div class="selectEquip">
        <el-select filterable v-model="currentEquip" placeholder="选择设备">
          <el-option v-for="item in equipOptions" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
        <el-date-picker popper-class="sampleDataDatePicker" size="large" v-model="timePicker" type="datetimerange"
          range-separator="~" start-placeholder="开始时间" end-placeholder="结束时间" value-format="YYYY-MM-DD HH:mm:ss"
          :disabled-date="disabledData" />
        <el-select filterable v-model="currentSampleData" placeholder="选择时间"
          @visible-change="handleSelectVisibleChange">
          <el-option v-for="item in sampleDataOptions" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
        <el-button @click="handleClickSearchSampleData">查询</el-button>
        <el-button>清空检测数据</el-button>
      </div>
    </div>
    <div class="sampleDataMain">
      <h3>单次检测数据</h3>
      <div id="sampleDataDom"></div>
    </div>
  </div>
</template>

<script>
import { getItem } from '../api'
import * as echarts from 'echarts'
let instance = null

export default {
  name: 'SampleData',
  data() {
    return {
      equipOptions: [],
      currentEquip: '',
      userCode: '',
      timePicker: [],
      currentSampleData: '',
      sampleDataOptions: [],
      //   单次检测数据的值
      sampleDataValue: [],
      originalArray: [],
      echartOption: {},
      disabledData: this.disabledData
    }
  },
  mounted() {
    this.userCode = getItem('userData').userCode
    this.initEquipOptions()
    this.echartOption = this.initOriginalOptions()
    window.addEventListener('resize', this.myResize)
  },
  methods: {
    myResize() {
      if (instance) {
        instance.resize()
      }
    },
    disabledData(date) {
      let currentDate = new Date().getTime()
      return currentDate < date.getTime()
    },
    async initEquipOptions() {
      const res = await this.$http.post(this.$urlObj.queryEquip, {
        userCode: this.userCode
      })
      if (res.status == 200 && res.data.resultCode == '0000') {
        let data = res.data.data
        this.equipOptions = []
        data.forEach(item => {
          let obj = {
            label: item.equipmentName + `(${item.sensorSerialArr[0]})`,
            value: item.sensorSerialArr[0]
          }
          this.equipOptions.push(obj)
        })
      }
    },
    // 初始化单次检测数据图表配置项
    initOriginalOptions() {
      let options = {
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value',
          name: '',
          min: 0,
          max: 500,
          splitLine: {
            lineStyle: {
              color: '#aaa'
            }
          }
        },

        grid: {
          containLabel: true,
          left: '40px',
          bottom: '10px',
          right: '10px'
        },
        tooltip: {
          trigger: 'axis'
        },
        toolbox: {
          feature: {
            dataZoom: {
              show: true,
              title: {
                zoom: '缩放',
                back: '还原'
              },
              yAxisIndex: false
            }
          }
        },
        series: [
          {
            data: [],
            type: 'line',
            smooth: true,
            symbol: 'none',
            lineStyle: {
              color: '#13A98C'
            },
            itemStyle: {
              color: '#13A98C'
            }
          }
        ]
      }
      return options
    },
    // 当选择时间下拉框出现时，触发
    async handleSelectVisibleChange(flag) {
      if (flag && this.currentEquip && this.timePicker) {
        const res = await this.$http.post(this.$urlObj.queryOriginalData, {
          userCode: this.userCode,
          serial: this.currentEquip,
          startDate: this.timePicker[0],
          endDate: this.timePicker[1]
        })
        if (res.status == 200 && res.data.resultCode == '0000') {
          this.sampleDataOptions = []
          let data = res.data.data
          this.originalArray = data
          data.forEach(item => {
            let obj = {}
            obj.value = item.collectionCode
            obj.label = item.startTime
            this.sampleDataOptions.push(obj)
          })
        }
      }
    },
    // 查询单次检测数据值
    async handleClickSearchSampleData() {
      if (this.currentSampleData) {
        let obj = this.originalArray.filter(item => {
          return item.collectionCode == this.currentSampleData
        })[0]
        let params = {
          userCode: this.userCode,
          rawDataId: obj.rawDataId,
          collectionName: obj.collectionName,
          collectionCode: obj.collectionCode
        }
        const res = await this.$http.post(this.$urlObj.queryOriginalDataValue, params)

        if (res.status == 200 && res.data.resultCode == '0000') {
          let values = []
          let data = res.data.data
          values = data.values
          if (instance) {
            instance.dispose()
          }
          let dom = document.getElementById('sampleDataDom')
          instance = echarts.init(dom)
          let xData = []
          for (let i = 0; i < values.length; i++) {
            xData.push(i)
          }
          this.echartOption.xAxis.data = xData
          this.echartOption.series[0].data = values
          this.echartOption && instance.setOption(this.echartOption)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sampleDataHeader {
  width: 100%;
  background-color: #1c1f30;
  margin-top: 20px;
  padding: 10px 0;
  color: #fff;
  display: flex;

  .selectEquip {
    .el-select {
      //   margin-left: 10px;
      //   margin-right: 10px;
      width: 100%;
      max-width: 365px;
      margin-bottom: 10px;

      @media screen and (min-width: 500px) {
        width: auto;
        margin-right: 10px;
      }
    }

    :deep(.el-date-editor) {
      //   width: 365px;
      max-width: 365px;
      margin-bottom: 10px;
      margin-right: 10px;
    }

    .el-button {
      width: 120px;
      background-color: transparent;
      color: #fff;
      margin-left: 0;
      margin-right: 10px;
      border-color: #0099ff;

      &:hover {
        background-color: #0099ff;
      }
    }
  }
}

.sampleDataMain {
  width: 100%;
  height: 400px;
  background-color: #1c1f30;
  margin-top: 20px;
  padding-top: 10px;
  box-sizing: border-box;
  position: relative;

  h3 {
    color: #fff;
    position: absolute;
    left: 10px;
    top: 10px;
  }

  #sampleDataDom {
    width: 100%;
    height: 100%;
  }
}

.el-select-dropdown__item.selected {
  background-color: #0099ff;
}

:deep(.el-range-editor.el-input__inner) {
  background-color: transparent;
  border-color: #758aaa;

  input {
    background-color: transparent;
    color: #0099ff;
  }

  .el-range-separator {
    color: #fff;
  }
}
</style>
